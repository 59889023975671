import Dialog from "../Dialog"

import "./Descriptions.styl"

const Descriptions = ({ values = [] }) => (
  <section className="descriptions">
    <div className="descriptions__header">
      <h2 className="descriptions__title">Entenda os critérios</h2>
      <p className="descriptions__definition">
        Veja o que é levado em consideração para o cálculo em cada critério
        levantado.
      </p>
    </div>
    <div className="descriptions__dialogs">
      {values.map((item) => (
        <Dialog title={item.title} key={item.title}>
          {item.description}
        </Dialog>
      ))}
    </div>
  </section>
)

export default Descriptions
