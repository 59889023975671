import Icon from "components/Icon"

import "./Disclaimer.styl"

const Disclaimer = ({ children }) => (
  <aside className="disclaimer">
    <Icon className="disclaimer__icon" name="alert" />
    <div className="disclaimer__content">{children}</div>
  </aside>
)

export default Disclaimer
