import { Link } from "gatsby"
import Card from "components/Card"
import ValueGroup from "../../../components/ValueGroup"
import usePreview from "./hooks"

import "./Preview.styl"

const Preview = () => {
  const { total, values } = usePreview()

  return (
    <Card className="preview" spacing="none">
      <section className="preview__header">
        <p className="preview__sentence">
          Essa é a prévia da remuneração mensal aproximada até o dia de hoje:
        </p>
        <p className="preview__value">{total}</p>
      </section>
      <div className="preview__body">
        <section className="preview__content">
          <ValueGroup title="Período" values={values?.period} />
          <ValueGroup title="Originação" values={values?.origination} />
          <ValueGroup title="Carteira" values={values?.wallet} />
        </section>
        <Link
          className="preview__link"
          to={ROUTES.microloansRemunerationDetails.path}
        >
          Ver detalhes do cálculo
        </Link>
      </div>
    </Card>
  )
}

export default Preview
