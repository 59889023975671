import { useState } from "react"

const useDialog = () => {
  const [opened, setOpened] = useState(false)

  const toggleDialog = () => {
    setOpened(!opened)
  }

  return {
    opened,
    toggleDialog,
  }
}

export default useDialog
