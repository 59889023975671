import { useEffect, useState } from "react"
import services from "services"

const useRemuneration = () => {
  const [descriptions, setDescriptions] = useState([])

  const fetchDescriptions = () => {
    services.microloans.remuneration.descriptions().then(setDescriptions)
  }

  useEffect(() => {
    fetchDescriptions()
  }, [])

  return { descriptions }
}

export default useRemuneration
