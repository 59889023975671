import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Descriptions from "./components/Descriptions"
import Disclaimer from "./components/Disclaimer"
import Preview from "./components/Preview"
import useRemuneration from "./hooks"

import "./Main.styl"

const Main = ({ pageContext: { business } }) => {
  const { descriptions } = useRemuneration()

  return (
    <Can
      business={business.business_permission}
      see="remuneration"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <div className="remuneration">
        <PageHeader title="Remuneração" />
        <main className="remuneration__content">
          <section className="remuneration__section">
            <Disclaimer>
              <p>A remuneração não contempla contratos Microinvest.</p>
              <p>
                O valor final poderá sofrer alterações com base na carteira e
                demais critérios de remuneração que ocorram até o dia do
                fechamento.
              </p>
            </Disclaimer>
            <Descriptions values={descriptions} />
          </section>
          <section className="remuneration__preview">
            <Preview />
          </section>
        </main>
      </div>
    </Can>
  )
}

export default Main
