import Icon from "components/Icon"
import classnames from "classnames"
import useDialog from "./hooks"

import "./Dialog.styl"

const Dialog = ({ title, children }) => {
  const { opened, toggleDialog } = useDialog()

  return (
    <div className="dialog">
      <button className="dialog__button" onClick={toggleDialog}>
        <p className="dialog__text">{title}</p>
        <Icon
          className={classnames("dialog__icon", {
            "dialog__icon--opened": opened,
          })}
          name="arrow-down"
        />
      </button>
      <dialog className="dialog__accordion" open={opened}>
        {children}
      </dialog>
    </div>
  )
}

export default Dialog
