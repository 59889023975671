import { useState, useEffect, useCallback } from "react"
import services from "services"

const usePreview = () => {
  const [total, setTotal] = useState("ND")
  const [values, setValues] = useState({})

  const handleValues = useCallback(({ total, values }) => {
    setTotal(total)
    setValues({
      period: values.period,
      origination: values.origination,
      wallet: values.wallet,
    })
  }, [])

  const fetchValues = useCallback(() => {
    services.microloans.remuneration.values().then(handleValues)
  }, [handleValues])

  useEffect(() => {
    fetchValues()
  }, [fetchValues])

  return { total, values }
}

export default usePreview
